// Dependencies
// import Bugsnag from "@bugsnag/js";
// import BugsnagPluginReact from "@bugsnag/plugin-react";
import { ToastContainer } from "react-toastify";
import AuthProvider from "../providers/auth-provider";
import React from "react";

// Stylesheets
import "react-phone-number-input/style.css";
import "../public/global.css";

import "../public/assets/css/page.css";
// import "tailwindcss/tailwind.css";
import "../public/assets/css/sidebar.css";
import "../public/assets/css/style.css";
import "../public/style.css";
import "../public/assets/css/intlTelInput.css";
import "react-toastify/dist/ReactToastify.css";
import "react-date-picker/dist/DatePicker.css";
import "react-time-picker/dist/TimePicker.css";
import "react-calendar/dist/Calendar.css";
import "react-phone-input-2/lib/style.css";
import "draft-js/dist/Draft.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import "../public/vendor/bootstrap-datepicker/css/bootstrap-datepicker3.css";
import "../public/vendor/bootstrap-timepicker/css/bootstrap-timepicker.css";
import http from "@/utils/http";
import { SWRConfig } from "swr";

// if (!Bugsnag._client) {
//   Bugsnag.start({
//     apiKey: "0e8c50e78a17c9185120a97d0954eb9f",
//     plugins: [new BugsnagPluginReact()],
//   });
// }

// const ErrorBoundary = Bugsnag.getPlugin("react").createErrorBoundary(React);
// import ErrorBoundary from "../components/errorBoundaries";
// This default export is required in a new `pages/_app.js` file.
export default function MyApp({ Component, pageProps }) {
  return (
    // <ErrorBoundary>
    <SWRConfig
      value={{
        fetcher: (url) =>
          http.get<never, any>(url).then((response) => response),
        dedupingInterval: 1000 * 60 * 5,
        shouldRetryOnError: false,
        revalidateOnFocus: true,
        revalidateOnReconnect: true,
      }}
    >
      <AuthProvider>
        <Component {...pageProps} />
        <ToastContainer
          autoClose={2000}
          hideProgressBar={false}
          newestOnTop={true}
          closeOnClick
          pauseOnFocusLoss={false}
          draggable
          limit={5}
        />
      </AuthProvider>
    </SWRConfig>
    // </ErrorBoundary>
  );
}
