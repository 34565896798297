import AuthContext from '../context/auth-context'
import authReducer from '../reducers/auth-reducer'
import { useReducer, useState, useEffect } from 'react'
import Cookie from 'js-cookie'

export default function AuthProvider(props) {

  const [state, dispatch] = useReducer(authReducer, { isLoggedIn: false })
  const [ready, setReady] = useState(false)

  // added this in order to get the city code code and to avoid prop dridling
  const [selectedOptionCityCode, setSelectedOptionCityCode] = useState("ACC");

// console.log(state,'state');
  const loginFunction = (payload) => {
    // console.log("Loggin in...")
    dispatch({ type: 'LOGIN', payload: payload })
  }

  const logoutFunction = () => {
    // console.log("Loggin out...")
    dispatch({ type: 'LOGOUT' })
  }

  const refreshFunction = (payload) => {
    // console.log("Refreshing session...")
    dispatch({ type: 'REFRESH', payload: payload })
  }

  const setCountry = (payload) => {
    // console.log("setting country...")
    dispatch({ type: 'SET_COUNTRY', payload: payload })
  }

  async function _init_() {
    try {
      const authCookie = Cookie.get("loggedIn")
      const userSession = Cookie.get("client")
      // console.log(authCookie)
      // console.log(userSession)

      if (authCookie === "true" && userSession) {
        // console.log("cookie retrieved")
        // console.log(authCookie)
        // console.log(JSON.parse(userSession))
        const newState = {
          authenticated: true,
          token: JSON.parse(userSession).token,
          country_code: JSON.parse(userSession).country_code,
        }

        refreshFunction(newState)
        setReady(true)
      }
      else {
        // console.log("Cookies not set")
        setReady(true)
        throw "Cookies not set"
      }

    } catch (error) {
      // console.log(error)
      setReady(true)
    }
  }

  useEffect(() => {
    // console.log(state)
    _init_()
  }, [])

  return (
    <AuthContext.Provider value={{ GLOBAL_OBJ: state, AUTH_LOGIN: loginFunction, AUTH_LOGOUT: logoutFunction, SET_COUNTRY: setCountry, selectedOptionCityCode, setSelectedOptionCityCode }}>
      {props.children}
    </AuthContext.Provider>
  )
}
