import Cookie from "js-cookie";
import moment from "moment";

const authReducer = (state, action) => {
  // console.log(state)
  switch (action.type) {
    case "LOGIN":
      // console.log(action.payload)
      let authenticated;
      /**Authentication requests done here */
      try {
        Cookie.set("loggedIn", "true", { expires: 7, sameSite: "lax" });
        var userData = JSON.stringify(action.payload);
        // console.log(userData);
        Cookie.set("client", userData, { expires: 7, sameSite: "lax" });
        sessionStorage.setItem("client", userData);
        sessionStorage.setItem("loggedIn", true);
        // console.log("Cookies set")
        authenticated = true;
      } catch (error) {
        // console.log(error)
        authenticated = false;
      }

      /** Always return state */
      return {
        ...state,
        isLoggedIn: authenticated,
        token: action.payload.token,
        country_code:
          action.payload.location && action.payload.location.country_code
            ? action.payload.location.country_code
            : "GH",
      };

    case "REFRESH":
      // console.log(action.payload)
      sessionStorage.setItem("client", JSON.stringify(action.payload));

      /** Always return state */
      return {
        ...state,
        isLoggedIn: action.payload.authenticated,
        token: action.payload.token,
        country_code:
          action.payload.location && action.payload.location.country_code
            ? action.payload.location.country_code
            : "GH",
      };

    case "SET_COUNTRY":
      // console.log(action.payload)

      /** Always return state */
      return { ...state, country_code: payload };

    case "LOGOUT":
      // console.log("loggin out...")
      /** Destroy all cookies or storage */

      Cookie.remove("loggedIn", { sameSite: "lax" });
      Cookie.remove("client", { sameSite: "lax" });

      sessionStorage.removeItem("loggedIn");
      sessionStorage.removeItem("client");

      return { ...state, isLoggedIn: false, token: null, country_code: null };
    default:
      /** Always return state */
      return state;
  }
};

export default authReducer;
